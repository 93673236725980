<script>
	import { t, locale, locales } from "./i18n";
	import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
	import { onMount, onDestroy, createEventDispatcher } from 'svelte/internal';
	import { selectedThemeStore, selectedModelStore} from './store.js';
	import UserMessage from './UserMessage.svelte'
	import AssistantMessage from './AssistantMessage.svelte'
	import Stagebar from './Stagebar.svelte';
	import CXStagebar from './CXStagebar.svelte';
	import Modal from './Modal.svelte';
	import App from "./App.svelte";
	import { user } from './store.js';
	let _user;
	user.subscribe(value => {
		_user = value;
	});

	let chatroom;
	let userMessage;
	let showModal = true;
	export let stage;
	export let status;
	let complete = false;
	let started = false;
	let typing = false;
	let message = "";
	let percent = 0;
	let points = 0;
	let attempts = 0;

		var recLangs = [
		{"English": "en-US"},
		{"French": "fr-FR"},
		{"Spanish": "es-ES"},
		{"German": "de-DE"},
		{"Italian": "it-IT"},
		{"Portuguese": "pt-PT"},
		{"Chinese": "zh-CN"},
		{"Japanese": "ja-JP"},
		{"Korean": "ko-KR"},
		{"Thai": "th-TH"}		
	];

	let _language = undefined;
	let selectedModelId = 2;
	let selectedModel;

	let userId;
	let userName;
	function generateRandomUserID() {
	  return 'uid_' + Math.random().toString(36).substring(2, 15);
	}



	let attempt = false;
	
	$: {
		if(!showModal){
				userId = _user.id; //GetStudentID();
				userName = _user.username;//GetStudentName();
				connection.start().then(() => {
					console.log("connected");
					console.log(userId);
					connection.invoke("Start", JSON.stringify({model:selectedModelId, language: _language, userId, userName, attempt}))
					.catch(function (err) {
						 return console.error(err.toString());
					});
				}).catch((err) => {
					console.log(err);
				});
				let txtMessage = document.getElementById("message");
				if(txtMessage !== null){
					txtMessage.focus();
					txtMessage.addEventListener('input', autoResize, false)
				}

		}
	}

	const connection = new HubConnectionBuilder()
		.withUrl("/chat")
		.configureLogging(LogLevel.Information)
		.withAutomaticReconnect()
		.build();

		onMount(async () => {
			showModal = true;
		connection.on("Receive", function (data) {
			console.log(data);

			if(data.type == "Model"){
				selectedModel = data.model;
				console.log(selectedModel);
				chatroom= window.$('#chatroom')[0]
			}
			else if (data.type == 'User') 
			{
				userMessage = new UserMessage({
					target: chatroom,
					props: {
						message: data.message
					}
				});
				typing=true;
			}
			else if (data.type == 'Assistant' && ((data.status == undefined || data.status == null || data.status.trim() === "In Progress") || !started))
			{
				const assistantMessage = new AssistantMessage({
					target: chatroom,
					props: {
						data: data,
						name: selectedModel.name
					}
				});	
				if(data.percent !== undefined) percent = data.percent;
				if(data.points !== undefined) {
					points = data.points;
					//RecordTest(points, 100);
				}
				if(data.stage !== undefined) console.log(data.stage);
				if(data.status !== undefined) console.log(data.status);	
				userMessage.setData(data);
				stage = data.stage;
				status = data.status;
				typing=false;
				started = true;
			}
			else if (data.type == 'Assistant' && data.status.trim() === "Failed")
			{
				//RecordStatus("failed");
				selectedModel.story = $t("chat.unfortunately");
				const assistantMessage = new AssistantMessage({
					target: chatroom,
					props: {
						data: data,
						name: selectedModel.name
					}
				});
				if(data.percent !== undefined) percent = data.percent;
							if(data.points !== undefined) {
								points = data.points;
								//RecordTest(points, 100);
							}
				if(data.stage !== undefined) console.log(data.stage);
				if(data.status !== undefined) console.log(data.status);
				userMessage.setData(data);
				stage = data.stage;
				status = data.status;
				typing=false;
			}
			else if (data.type == 'Assistant' && data.status.trim() === "Completed")
			{
				//RecordTest(100, 100);
				//RecordStatus("completed");
				selectedModel.story = $t("chat.congratulations");
				const assistantMessage = new AssistantMessage({
				target: chatroom,
				props: {
				data: data,
				name: selectedModel.name
				}
				});
				if(data.percent !== undefined) percent = data.percent;
				if(data.stage !== undefined) console.log(data.stage);
				if(data.status !== undefined) console.log(data.status);
				userMessage.addedPoints = data.addedPoints;
				userMessage.feedback = data.feedback;
				userMessage.stage = data.stage;
				userMessage.status = data.status;
				stage = data.stage;
				status = data.status;
				typing=false;

			}

	});
		});

	  onDestroy(async () => {
		await connection.stop();
	  });

		function sendMessage() {
		if (message != "") {
			connection.invoke("Send", JSON.stringify({message, model:selectedModelId, language: _language, userId, userName}))
				.catch(function (err) {
					 return console.error(err.toString());
				});
			message = "";
		}
	}

	function handleKeydown(e) {
		if (e.keyCode == 13 && e.ctrlKey)
		{
			this.value += "\n";
			this.style.height = 'auto';
			this.style.height = this.scrollHeight -15 + 'px';
			e.preventDefault();
		}
		else if (e.key == 'Enter') {
			 sendMessage();
			 this.style.height = '45px';
			 e.preventDefault();
		 }
	}

	


	function startNewChat() {
		attempt = true;
		showModal = false;
		window.$('#chatroom')[0].innerHTML = "";
		message = "";
		points = 0;
		percent = 0;
		started = false;
		document.getElementById('message').focus();
		connection.stop().then(() => connection.start().then(() => {
		console.log("connected");
		connection.invoke("Start", JSON.stringify({model:selectedModelId, language: _language, userId, userName, attempt}))
		.catch(function (err) {
			return console.error(err.toString());
		});
		}).catch((err) => {
		console.log(err);
		})
		);
	}

	function autoResize() {
	this.style.height = 'auto';
	this.style.height = this.scrollHeight - 15 + 'px';
	}

	function getLangCode(language) {
	for (let i = 0; i < recLangs.length; i++) {
		let langObj = recLangs[i];
		let langName = Object.keys(langObj)[0];
		if (langName === language) {
			return langObj[langName];
		}
	}
	return null; // Language not found
}

</script>

	<div class="custom-container">
		<div class="custom-content">
			{#if selectedModel !== undefined}
			<div class="chat-story">
				<div class="chat-story-black">
					<div class="chat-image">
						<img src="{selectedModel?.image}" class="chat-image" />
					</div>

					<div class="chat-story-black-description">
						<div class="chat-story-black-description-title">
							<div class="h-logo"></div>
								<div class="chat-story-black-description-title-name">
									<h3>{selectedModel?.name}</h3>
								</div>
							<button class="btn btn-primary" type="button" id="newChat" on:click={startNewChat}></button>
						</div>

					
						{#if selectedModelId === 2}
							<CXStagebar bind:status bind:points/>
						{/if}
					</div>
				</div>

				<div class="chat-story-description">
					<p>{@html selectedModel?.story}</p>
				</div>
			</div>
			{/if}
			<div class="custom-content--inner" id="chatroom2">
				<div class="custom-content--inner--width">
					<div id="chatroom" class="chat">
					</div>
				</div>

				<div class="form-container">
				{#if typing}
					<div class="load-6" id="typing">
						<div class="letter-holder">
							<div class="l-1 letter">T</div>
							<div class="l-2 letter">y</div>
							<div class="l-3 letter">p</div>
							<div class="l-4 letter">i</div>
							<div class="l-5 letter">n</div>
							<div class="l-6 letter">g</div>
							<div class="l-7 letter">.</div>
							<div class="l-8 letter">.</div>
							<div class="l-9 letter">.</div>
						</div>
					</div>
				{/if}
				{#if selectedModel !== undefined}
					<div class="form">
						<textarea id="message" type="text" class="form-control form-input" placeholder="Type anything..." style="resize:none; overflow-y:hidden; height:45px;"  bind:value={message} on:keydown={handleKeydown}></textarea>
						<span class="left-pan" on:click={sendMessage}></span>
					</div>
				{/if}
			</div>
			</div>
		</div>
	</div>
<Modal bind:showModal>
	<h2 slot="header">
		{$t("modal.title")}
	</h2>

			<p>
				<select class="form-control" bind:value={_language}>
				  {#each locales as l}
					<option value={l}>{l}</option>
				  {/each}
				</select>
			</p>
</Modal>